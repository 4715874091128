import Head from "next/head";
import useSite from "../../hooks/use-site";

const SEO = () => {
  const site = useSite();

  const title = site?.[site.postType]?.seo?.title
    ? site?.[site.postType]?.seo.title
    : site?.generalSettings?.title;
  const description = site?.[site.postType]?.seo?.metaDesc
    ? site?.[site.postType]?.seo?.metaDesc
    : false;
  const featuredImage = site?.[site.postType]?.featuredImage;
  const schema = site?.[site.postType]?.seo?.schema.raw;
  const canonical = site?.[site.postType]?.seo?.canonical;

  const metaRobotsNoIndex = site?.[site.postType]?.seo?.metaRobotsNoindex;

  const customSchema = site?.[site.postType]?.saswpCustomSchema;

  console.log("SEO", site?.[site.postType]);
  return (
    <>
      <Head>
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        {metaRobotsNoIndex == "noindex" && (
          <meta name="robots" content="noindex" />
        )}
        {title && (
          <>
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta property="og:title" content={title} />
            <meta property="twitter:title" content={title} />
          </>
        )}
        {description && (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="twitter:description" content={description} />
          </>
        )}
        {featuredImage && (
          <>
            <meta
              property="og:image"
              content={featuredImage?.node?.sourceUrl}
            />
            <meta
              property="twitter:image"
              content={featuredImage?.node?.sourceUrl}
            />
          </>
        )}
        {canonical && (
          <link
            rel="canonical"
            href={canonical.replace(
              process.env.NEXT_PUBLIC_WORDPRESS_URL,
              `${process.env.NEXT_PUBLIC_FRONTEND_URL}/`
            )}
          ></link>
        )}
        {schema && <script type="application/ld+json">{schema}</script>}
        {customSchema &&
          customSchema.json_ld &&
          (() => {
            try {
              const parsedSchema = JSON.parse(customSchema.json_ld);
              return (
                <script type="application/ld+json">
                  {JSON.stringify(parsedSchema, null, 2)}
                </script>
              );
            } catch (error) {
              console.error("Invalid JSON in customSchema.json_ld:", error);
              return null; // Or handle the error gracefully
            }
          })()}
      </Head>
    </>
  );
};

export default SEO;
