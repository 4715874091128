import { gql } from "@apollo/client";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  NavigationMenuFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";
import SiteWrapper from "../components/SiteWrapper";
import PageBanner from "../components/PageBanner";
import Archive from "../components/Archive";

export default function Component(props) {
  let fullProps = { ...props.data };

  let postsProps = { ...props.data.posts };

  const seoProps = { ...fullProps.nodeByUri.seo };

  seoProps.metaRobotsNoindex = "noindex";

  postsProps.title = fullProps.nodeByUri.name;
  postsProps.seo = seoProps;
  fullProps.posts = postsProps;

  fullProps.posts.nodes = fullProps.nodeByUri.posts.edges.map(
    ({ node }) => node
  );

  const siteProps = Object.assign({}, fullProps, {
    postType: "posts",
  });
  return (
    <SiteWrapper siteData={siteProps}>
      <PageBanner />
      <Archive />
    </SiteWrapper>
  );
}

Component.variables = ({ uri }, ctx) => {
  return {
    uri,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetArchivePage(
    $uri: String!
    $headerLocation: MenuLocationEnum
    $formId: ID = "1"
  ) {
    nodeByUri(uri: $uri) {
      ... on Category {
        name
        seo {
          canonical
          title
          metaDesc
          focuskw
          schema {
            raw
          }
        }
        posts {
          edges {
            node {
              date
              excerpt
              id
              link
              title
              featuredImage {
                node {
                  id
                  sourceUrl
                  altText
                  mediaDetails {
                    width
                    height
                  }
                  sizes(size: MEDIUM)
                }
              }
              categories {
                nodes {
                  categoryId
                  name
                }
              }
              tags {
                nodes {
                  name
                  link
                  id
                }
              }
            }
          }
        }
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $formId, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
