import { gql } from "@apollo/client";
import * as MENUS from "../constants/menus";
import {
  BlogInfoFragment,
  FeatureImageFragment,
  NavigationMenuFragment,
  SiteSettingsFragment,
} from "../fragments/GeneralSettings";
import { SingleFormFragment } from "../fragments/GravityForms";
import SiteWrapper from "../components/SiteWrapper";
import SinglePost from "../components/SinglePost";

export default function Component(props) {
  // Loading state for previews
  if (props.loading) {
    return <>Loading...</>;
  }

  const siteProps = Object.assign({}, props.data, {
    acfData: JSON.parse(props.data.post.blockData),
    postType: "post",
  });
  return (
    <SiteWrapper siteData={siteProps}>
      <SinglePost />
    </SiteWrapper>
  );
}

Component.variables = ({ databaseId }, ctx) => {
  return {
    databaseId,
    headerLocation: MENUS.PRIMARY_LOCATION,
    asPreview: ctx?.asPreview,
  };
};

Component.query = gql`
  ${BlogInfoFragment}
  ${NavigationMenuFragment}
  ${FeatureImageFragment}
  ${SingleFormFragment}
  ${SiteSettingsFragment}
  query GetPageData(
    $databaseId: ID!
    $headerLocation: MenuLocationEnum
    $asPreview: Boolean = false
    $formId: ID = "1"
  ) {
    post(id: $databaseId, idType: DATABASE_ID, asPreview: $asPreview) {
      title
      blockData
      content
      date
      ...FeaturedImageFragment
      pageSettings {
        hideFooterForm
      }
      shareLinks {
        facebook
        pinterest
        twitter
      }
      categories {
        nodes {
          categoryId
          name
          link
        }
      }
      tags {
        nodes {
          name
          link
          id
        }
      }
      seo {
        canonical
        title
        metaDesc
        focuskw
        schema {
          pageType
          articleType
          raw
        }
      }
      saswpCustomSchema {
        json_ld
      }
    }
    themeStylesheet {
      value
    }
    generalSettings {
      ...BlogInfoFragment
    }
    footer1: menuItems(where: { location: FOOTER_COLUMN_1 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer2: menuItems(where: { location: FOOTER_COLUMN_2 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer3: menuItems(where: { location: FOOTER_COLUMN_3 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }
    footer4: menuItems(where: { location: FOOTER_COLUMN_4 }, last: 10000) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    headerMenuItems: menuItems(
      where: { location: $headerLocation }
      last: 10000
    ) {
      nodes {
        ...NavigationMenuItemFragment
      }
    }

    themeGeneralSettings {
      ...SiteSettingsFragment
    }

    newsletterSignup: gfForm(id: $formId, idType: DATABASE_ID) {
      ...SingleFormFields
    }
  }
`;
